import { of } from 'rxjs';
import { filter, map, mergeMap, catchError } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints, QUICKSEARCH_RESULT_LIMIT } from 'globalConstants';
import {
  companySelectApiSuccess,
  companySelectApiFail,
} from 'store/actions/companySelectActions';
import { CompanySelectActionTypes } from 'connected/CompanySelect/types';

type CompanySelectEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const companySelectEpic: Epic = (
  action$,
  state$,
  { authRequest }: CompanySelectEpicDependencies,
) =>
  action$.pipe(
    ofType(CompanySelectActionTypes.COMPANY_SELECT_QUERY),
    filter((action) => action.payload.length >= 3),
    mergeMap((action) => {
      const {
        includeIsPrivate,
        payload: searchTerm,
        excludeCurrentCompany,
      } = action;

      const encodedTerm = encodeURIComponent(searchTerm);
      const url = endpoints.combinedCompanySearch(
        encodedTerm,
        QUICKSEARCH_RESULT_LIMIT,
        includeIsPrivate,
        excludeCurrentCompany,
      );

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          return companySelectApiSuccess(ajaxResponse.response);
        }),
      );
    }),
    catchError((error) => of(companySelectApiFail(error))),
  );

export default companySelectEpic;
