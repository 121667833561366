import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

import {
  quickSearchQuery,
  quickSearchReset,
  quickSearchSetVertical,
  quickSearchToggleVertical,
} from 'store/actions/quickSearchActions';

import { ToggleButton } from 'components/CallToActions/ToggleButton/ToggleButton';
import { CloseButton } from 'components/CallToActions/CloseButton/CloseButton';
import SearchTextInput from 'components/Forms/Inputs/SearchInput/SearchTextInput';
import QuickSearchResults from './SearchResults/SearchResults';

import {
  FieldsetWrapper,
  QuickSearchTitle,
  QuickSearchWrapper,
  TextInputWrapper,
  CloseButtonWrapper,
  BuildingIconStyled,
  CompanyIconStyled,
  SearchIconStyled,
  customInputStyles,
  SearchTypeIndicator,
} from './QuickSearchStyled';

import searchOptions, {
  QUICK_SEARCH_COPY,
  QUICK_SEARCH_OPTIONS,
} from './constants';

import {
  QuickSearchProps,
  QuickSearchFormProps,
  CloseButtonWithFadeProps,
} from './types';

const CloseButtonWithFade = (props: CloseButtonWithFadeProps) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsHidden(false), 500);
  }, []);

  return isHidden ? null : (
    <CloseButtonWrapper>
      <CloseButton {...props} />
    </CloseButtonWrapper>
  );
};

const SearchWrapper: React.FC<QuickSearchFormProps> = ({
  onSubmit,
  formEmbed = false,
  children,
}) => {
  return formEmbed ? (
    <div>{children}</div>
  ) : (
    <form name="quick-search" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

const getIcon = (isBuilding: boolean) => {
  if (isBuilding) {
    return <BuildingIconStyled aria-hidden="true" />;
  }
  return <CompanyIconStyled aria-hidden="true" />;
};

const QuickSearch = ({
  onInputFocus,
  onCloseButtonClick,
  onResultItemClick,
  onSearchTermSet,
  searchIsActive,
  showSummary = true,
  defaultSearchType = searchOptions.buildings,
  isSingleSearchType = false,
  hideCloseButton = false,
  mode = 'light',
  disableNonCanonicalResults = false,
  formEmbed = false,
  resetDetails = true,
}: QuickSearchProps) => {
  const [searchType, setSearchType] = useState(defaultSearchType);
  const dispatch = useDispatch();
  const [
    resourceId,
    searchTerm,
    vertical,
  ] = useSelector(({ details, quickSearch }: RootState) => [
    details.resourceID,
    quickSearch.searchTerm,
    quickSearch.vertical,
  ]);

  function closePanel() {
    dispatch(quickSearchReset(!resetDetails));

    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  }

  function onSearchInputChange(event: ChangeEvent<HTMLInputElement>) {
    const inputedTerm = event.target.value;

    const payload = {
      keyword: [inputedTerm, searchType.name],
      skipResetDetails: !resetDetails,
    };

    dispatch(quickSearchQuery(payload));
  }

  function onSearchInputKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape' && searchIsActive) {
      dispatch(quickSearchReset(!resetDetails));
    }
  }

  function onFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  function toggleSearchType() {
    const newSearchType =
      searchType.name === QUICK_SEARCH_OPTIONS.buildings.name
        ? searchOptions.companies
        : searchOptions.buildings;

    setSearchType(newSearchType);
    dispatch(quickSearchToggleVertical(newSearchType.name));
  }

  function resetPanelOnMount() {
    if (searchTerm.length > 0) if (onSearchTermSet) onSearchTermSet();

    if (vertical !== defaultSearchType.name) {
      if (vertical === QUICK_SEARCH_OPTIONS.buildings.name)
        dispatch(quickSearchSetVertical(QUICK_SEARCH_OPTIONS.buildings.name));
      else
        dispatch(quickSearchSetVertical(QUICK_SEARCH_OPTIONS.companies.name));
    }
  }

  useEffect(resetPanelOnMount, []);

  return (
    <QuickSearchWrapper>
      <div>
        {!formEmbed && (
          <QuickSearchTitle searchIsActive={searchIsActive}>
            {QUICK_SEARCH_COPY.title}
          </QuickSearchTitle>
        )}
        <SearchWrapper onSubmit={onFormSubmit} formEmbed={formEmbed}>
          <FieldsetWrapper>
            {isSingleSearchType ? (
              <SearchTypeIndicator>
                {getIcon(
                  searchType.name === QUICK_SEARCH_OPTIONS.buildings.name,
                )}
              </SearchTypeIndicator>
            ) : (
              <ToggleButton onClick={toggleSearchType}>
                {getIcon(
                  searchType.name === QUICK_SEARCH_OPTIONS.buildings.name,
                )}
                {searchType.label}
              </ToggleButton>
            )}
            <TextInputWrapper>
              <SearchTextInput
                name="quick-search"
                label={searchType.formLabel}
                onFocus={onInputFocus}
                labelIsHidden
                placeholder={searchType.formPlaceholder}
                customStyles={customInputStyles}
                onChange={onSearchInputChange}
                onKeyUp={onSearchInputKeyUp}
                value={searchTerm}
              />
              <SearchIconStyled />
            </TextInputWrapper>
          </FieldsetWrapper>
        </SearchWrapper>
        {!hideCloseButton && searchIsActive && (
          <CloseButtonWithFade onClick={closePanel} />
        )}
        {(!resourceId || !resetDetails) && searchTerm.length > 1 && (
          <QuickSearchResults
            searchType={searchType.name}
            showSummary={showSummary}
            onResultItemClick={onResultItemClick}
            mode={mode}
            disableNonCanonicalResults={disableNonCanonicalResults}
          />
        )}
      </div>
    </QuickSearchWrapper>
  );
};

export default QuickSearch;
