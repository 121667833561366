import React, { useState } from 'react';

import Select from 'components/Forms/Inputs/Select/Select';
import { FORM_BUILDER_COPY } from '../../constants';
import { StyledSelectWrapper } from '../FormMultilineStyled';
import {
  DEFAULT_OPTIONS,
  INITIAL_LINES_STATE,
  INITIAL_POSITION_STATE,
} from './constants';
import {
  AddBatchLabel,
  AddButton,
  ControlContainer,
  StyledAddIcon,
} from './BatchControlOrderedStyled';
import { BatchControlProps } from './types';
import { createLinesToInsertOptions } from './helpers';

const insertOptions = createLinesToInsertOptions();

const BatchControlOrdered = ({
  addBatchLabel = ['Add', 'items to the', 'of this group'],
  addButton = 'Insert',
  onInsertRows,
}: BatchControlProps) => {
  const [linesToInsert, setLinesToInsert] = useState(INITIAL_LINES_STATE);
  const [linesPosition, setLinePosition] = useState(INITIAL_POSITION_STATE);

  const onClickAddRows = () => {
    const quantity = parseInt(linesToInsert.value, 10);
    onInsertRows(quantity, linesPosition.value);
    setLinesToInsert(INITIAL_LINES_STATE);
    setLinePosition(INITIAL_POSITION_STATE);
  };

  return (
    <ControlContainer>
      <StyledAddIcon />
      <AddBatchLabel>{addBatchLabel[0]}</AddBatchLabel>
      <StyledSelectWrapper>
        <Select
          options={insertOptions}
          name="addLines"
          label={FORM_BUILDER_COPY.multiline.batch.label}
          labelIsHidden
          onSelectChange={(option) => setLinesToInsert(option)}
          selectedValue={linesToInsert}
          defaultValue={insertOptions[0]}
        />
      </StyledSelectWrapper>
      <AddBatchLabel>{addBatchLabel[1]}</AddBatchLabel>
      <StyledSelectWrapper>
        <Select
          options={DEFAULT_OPTIONS}
          name="linePosition"
          label={FORM_BUILDER_COPY.multiline.batch.label}
          labelIsHidden
          onSelectChange={(option) => setLinePosition(option)}
          selectedValue={linesPosition}
          defaultValue={DEFAULT_OPTIONS[0]}
        />
      </StyledSelectWrapper>
      <AddBatchLabel>{addBatchLabel[2]}</AddBatchLabel>
      <AddButton type="button" onClick={onClickAddRows}>
        {addButton}
      </AddButton>
    </ControlContainer>
  );
};

export default BatchControlOrdered;
