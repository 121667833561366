import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

import { BoldEmphasized } from 'styled/Global';
import { LinearLoader } from 'components/LinearLoader/LinearLoader';
import ReportErrorButton from 'connected/ReportError/ReportErrorButton';
import {
  QuickSearchResult,
  QuickSearchOnResultItemClick,
  QuickSearchColorMode,
} from 'connected/QuickSearch/types';
import {
  QuickSearchResultsContainer,
  QuickSearchResultsHeader,
  ResultsSummaryContainer,
  ResultsUnorderList,
  QuickSearchResultsLoaderWrapper,
} from './SearchResultsStyled';
import ResultItem from '../ResultItem/ResultItem';
import NoResults from '../NoResults/NoResults';
import { QUICK_SEARCH_COPY } from '../constants';

export type QuickSearchResultsProps = {
  searchType: string;
  showSummary: boolean;
  onResultItemClick?: QuickSearchOnResultItemClick;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
};

function renderResults(
  results: QuickSearchResult[],
  onResultItemClick?: QuickSearchOnResultItemClick,
  mode?: QuickSearchColorMode,
  disableNonCanonicalResults?: boolean,
) {
  return (
    <ResultsUnorderList>
      {results.map((result) => {
        return result.otherAddresses && result.otherAddresses.length ? (
          <li key={`${result.id}-parent`}>
            <ul>
              <ResultItem
                key={result.id}
                result={result}
                onResultItemClick={onResultItemClick}
              />
              {result.otherAddresses.map((child) => (
                <ResultItem
                  key={child.id}
                  result={child}
                  onResultItemClick={onResultItemClick}
                  isChild
                  mode={mode}
                  disableNonCanonicalResults={disableNonCanonicalResults}
                />
              ))}
            </ul>
          </li>
        ) : (
          <ResultItem
            key={result.id}
            result={result}
            onResultItemClick={onResultItemClick}
          />
        );
      })}
    </ResultsUnorderList>
  );
}

function renderNoResults(searchTerm: string, error: string) {
  return error ? (
    <NoResults searchTerm={searchTerm} error />
  ) : (
    <NoResults searchTerm={searchTerm} />
  );
}

const ResultsSummary = ({ total }: { total: number }) => {
  const { displaying, results } = QUICK_SEARCH_COPY.resultsSummary;
  const usePlural = total === 0 || total > 1;
  const capReached = total === 10;

  return (
    <ResultsSummaryContainer>
      {capReached ? displaying.capReached : displaying.lessThanCap}{' '}
      <BoldEmphasized>{total}</BoldEmphasized>{' '}
      {usePlural ? results.plural : results.singular}
    </ResultsSummaryContainer>
  );
};

const QuickSearchResults = ({
  showSummary,
  onResultItemClick,
  mode = 'light',
  disableNonCanonicalResults,
}: QuickSearchResultsProps) => {
  const { results, searchTerm, isFetching, error = '' } = useSelector(
    (state: RootState) => state.quickSearch,
  );
  const totalResults = results.length;

  if (!searchTerm) {
    return null;
  }

  if (isFetching) {
    return (
      <QuickSearchResultsLoaderWrapper>
        <LinearLoader mode="light" />
      </QuickSearchResultsLoaderWrapper>
    );
  }

  return (
    <QuickSearchResultsContainer>
      <QuickSearchResultsHeader>
        {showSummary && !error && (
          <>
            <ResultsSummary total={totalResults} />
            <ReportErrorButton mode="dark" />
          </>
        )}
      </QuickSearchResultsHeader>
      {totalResults
        ? renderResults(
            results,
            onResultItemClick,
            mode,
            disableNonCanonicalResults,
          )
        : renderNoResults(searchTerm, error)}
    </QuickSearchResultsContainer>
  );
};

export default QuickSearchResults;
