import styled from 'styled-components/macro';
import { ReactComponent as AddIcon } from 'assets/icons/add-circle-outline.svg';

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;

  select {
    padding: ${(props) => `2px ${props.theme.spacing.default} 0 10px`};
    min-width: 60px;
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  width: 18px;
  height: 18px;
`;

export const AddBatchLabel = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: 0 10px 0 0;
  font-weight: 700;
`;

export const AddButton = styled.button`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  color: ${(props) => props.theme.colorPalette.text.white};
  padding: 8px 12px;
  background: ${(props) => props.theme.colorPalette.branding.ink};
  margin-left: 10px;
`;
