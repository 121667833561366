import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  catchError,
  debounceTime,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import {
  quickSearchApiSuccess,
  quickSearchApiFail,
} from 'store/actions/quickSearchActions';
import { QuickSearchActionTypes } from 'connected/QuickSearch/types';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints, QUICKSEARCH_RESULT_LIMIT } from 'globalConstants';

type QuickSearchEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const quickSearchEpic: Epic = (
  action$,
  state$,
  { authRequest }: QuickSearchEpicDependencies,
) =>
  action$.pipe(
    ofType(QuickSearchActionTypes.QUICK_SEARCH_QUERY),
    debounceTime(300),
    filter((action) => action.payload.keyword[0].length >= 2),
    mergeMap((action) => {
      const [searchTerm, searchType] = action.payload.keyword;

      const url = endpoints.vertical.search(
        searchType,
        searchTerm.replace('&', '%26'),
        QUICKSEARCH_RESULT_LIMIT,
      );

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          return quickSearchApiSuccess(ajaxResponse.response);
        }),
      );
    }),
    catchError((error) => of(quickSearchApiFail(error))),
  );

export default quickSearchEpic;
