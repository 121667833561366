import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { globalVariables } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { Option, OptionGroup } from 'components/Forms/types';
import SelectSearch from 'components/Forms/Inputs/SelectSearch/SelectSearch';
import { ValidationProps } from 'connected/FormFlow/types';
import { isJsonString } from 'utils/string';
import {
  companySelectQuery,
  companySelectReset,
} from 'store/actions/companySelectActions';
import { CompanySelectResult } from './types';
import { COMPANY_SELECT_COPY } from './constants';

const parseSelectedValue = (valueToParse: string[]): Option[] | undefined => {
  return valueToParse?.map((value: string) => {
    if (!isJsonString(value)) {
      return { value, label: value };
    }
    const parsed = JSON.parse(value);
    return {
      value: parsed.id || parsed.companyRegistrationNumber,
      label: parsed.name,
    };
  });
};

type CompanySelectProps = {
  onChange: (option: Option) => void;
  selectedValue?: string[];
  label: string;
  name?: string;
  isDisabled?: boolean;
  validation?: ValidationProps;
  isMultiSelect?: boolean;
  onDelete: (isCompany: boolean) => void;
  canAddNew?: boolean;
  omitCompanyHouse?: boolean;
  includeIsPrivate?: boolean;
  excludeCurrentCompany?: string;
};

const CompanySelect = ({
  onChange,
  selectedValue,
  isDisabled = false,
  label,
  name,
  validation,
  isMultiSelect = false,
  onDelete,
  canAddNew = true,
  omitCompanyHouse = false,
  includeIsPrivate = false,
  excludeCurrentCompany,
}: CompanySelectProps) => {
  const dispatch = useDispatch();
  const [mappedResults, setMappedResults] = useState<OptionGroup[] | null>(
    null,
  );

  const { results, isFetching } = useSelector(
    (state: RootState) => state.companySelect,
  );

  const fetchedCompanies: CompanySelectResult = results;

  useEffect(() => {
    const mapResults = () => {
      const resultSet = fetchedCompanies
        ? [
            {
              label: COMPANY_SELECT_COPY.dbLabel,
              options: fetchedCompanies?.athena.data.map((item) => ({
                label: item.name,
                subLabel: item.address || '',
                value: JSON.stringify({
                  name: item.name,
                  id: item.id,
                }),
              })),
            },
            {
              label: COMPANY_SELECT_COPY.companyHouseLabel,
              options: fetchedCompanies?.companiesHouse.data.map((item) => ({
                label: item.name,
                regNumber: item.companyRegistrationNumber,
                subLabel: item.address || '',
                value: JSON.stringify({
                  name: item.name,
                  companyRegistrationNumber: item.companyRegistrationNumber,
                }),
              })),
            },
          ]
        : null;

      if (omitCompanyHouse && resultSet) {
        const payload = [resultSet[0]];

        setMappedResults(payload);
        return;
      }

      setMappedResults(resultSet);
    };
    mapResults();
  }, [fetchedCompanies, omitCompanyHouse]);

  const resetOnUnMount = () => {
    return () => {
      dispatch(companySelectReset());
    };
  };

  useEffect(resetOnUnMount, [name]);

  const fetchNewOptions = (searchTerm: string) => {
    dispatch(
      companySelectQuery(searchTerm, includeIsPrivate, excludeCurrentCompany),
    );
  };

  const resetOptions = () => {
    dispatch(companySelectReset());
    onDelete(true);
  };

  return (
    <SelectSearch
      id={name || 'company-select'}
      name={name || 'company-select'}
      options={mappedResults || []}
      isAsync
      canAddNew={canAddNew}
      isLoading={isFetching}
      label={label}
      onInputChange={fetchNewOptions}
      onChange={onChange}
      multipleSelect={
        isMultiSelect || (selectedValue && selectedValue.length > 1)
      }
      maxWidth={globalVariables.SELECT_MAX_WIDTH}
      isWide
      placeholder=""
      isDisabled={isDisabled}
      noOptionsMessage={COMPANY_SELECT_COPY.noOptions}
      loadingMessage={COMPANY_SELECT_COPY.loading}
      selectedOptions={selectedValue ? parseSelectedValue(selectedValue) : []}
      validation={validation}
      onDelete={resetOptions}
    />
  );
};

export default CompanySelect;
