import styled from 'styled-components/macro';

import { ReactComponent as Flag } from 'assets/icons/flag.svg';
import { MenuButton } from 'components/ActionsMenu/ActionsMenuStyled';

export const ReportErrorContainer = styled.div``;

const getLightDarkTheme = (light: string, dark: string, mode?: string) => {
  if (mode === 'dark') {
    return light;
  }

  return dark;
};

export const ReportErrorButton = styled(MenuButton)<{
  mode?: 'light' | 'dark';
}>`
  color: ${(props) =>
      getLightDarkTheme(
        props.theme.colorPalette.branding.white,
        props.theme.colorPalette.branding.ink,
        props.mode,
      )}
    svg {
    fill: ${(props) =>
      getLightDarkTheme(
        props.theme.colorPalette.branding.white,
        props.theme.colorPalette.branding.ink,
        props.mode,
      )};
  }

  font-size: ${(props) => props.theme.typography.paragraph.small};
`;

export const FlagIcon = styled(Flag)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
