import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'ramda/src/isEmpty';

import { RootState } from 'store/rootReducer';
import { getSearchQuery } from 'store/selectors/searchQuery';
import { getVerticalSelector } from 'store/selectors/detailsSelectors';
import { ReportErrorCopy, EMAIL, EMAIL_SUBJECT } from './constants';
import {
  ReportErrorButton as ErrorButton,
  FlagIcon,
} from './ReportError.styled';

interface ReportErrorButtonProps {
  mode?: 'light' | 'dark';
  isSearchResults?: boolean;
  isDetails?: boolean;
}

const ReportErrorButton: React.FC<ReportErrorButtonProps> = ({
  mode = 'light',
  isSearchResults = false,
  isDetails = false,
}) => {
  const location = useLocation();
  const vertical = useSelector(getVerticalSelector);
  const searchQuery = useSelector(getSearchQuery) as Record<string, string>;
  const searchTerm = useSelector(
    (state: RootState) => state.quickSearch.searchTerm,
  );
  const resourceId = useSelector(
    (state: RootState) => state.details.resourceID,
  );

  const onReportErrorClick = () => {
    let searchTitle = '';
    let searchResults = '';
    let searchBody = '';

    if (!isEmpty(searchTerm)) {
      searchTitle = `Search Term - ${searchTerm} \n`;
    }

    if (isSearchResults) {
      searchResults = `Request Payload - ${JSON.stringify(searchQuery).replace(
        /,/g,
        ',\n',
      )}`;
    }

    if (isDetails) {
      searchBody = `Request Payload - ${vertical} - ${resourceId} \n`;
    }

    const urlPath = `${window.location.origin}${location.pathname}${location.search}`;
    const subject = `${EMAIL_SUBJECT} (#${Date.now()})`;
    const body = `Please provide details of the error you would like to report here: \n \n \n \n \n
Do not change any data below this line \n
-------------- \n
URL - ${urlPath} \n
${searchTitle}
${searchResults}
${searchBody}
`;

    window.location.href = `mailto:${EMAIL}?subject=${subject}&body=${encodeURIComponent(
      body,
    )}`;
  };

  return (
    <ErrorButton onClick={onReportErrorClick} mode={mode}>
      <FlagIcon />
      {ReportErrorCopy.button}
    </ErrorButton>
  );
};

export default ReportErrorButton;
