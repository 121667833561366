import { GlobalApiFailError } from 'store/actions/types';

export enum CompanySelectActionTypes {
  COMPANY_SELECT_QUERY = 'COMPANY_SELECT_QUERY',
  COMPANY_SELECT_API_SUCCESS = 'COMPANY_SELECT_API_SUCCESS',
  COMPANY_SELECT_API_FAIL = 'COMPANY_SELECT_API_FAIL',
  COMPANY_SELECT_RESET = 'COMPANY_SELECT_RESET',
  COMPANY_SELECT_TOGGLE_VERTICAL = 'COMPANY_SELECT_TOGGLE_VERTICAL',
}

export type CompanySelectQueryAction = {
  type: CompanySelectActionTypes.COMPANY_SELECT_QUERY;
  payload: string;
  includeIsPrivate: boolean;
  excludeCurrentCompany?: string;
};

export type CompanySelectApiSuccessAction = {
  type: CompanySelectActionTypes.COMPANY_SELECT_API_SUCCESS;
  payload: CompanySelectResult;
};

export type CompanySelectApiFailAction = {
  type: CompanySelectActionTypes.COMPANY_SELECT_API_FAIL;
  payload: GlobalApiFailError;
};

export type CompanySelectResetAction = {
  type: CompanySelectActionTypes.COMPANY_SELECT_RESET;
};

export type CompanySelectResult = {
  athena: {
    data: {
      id: string;
      name: string;
      address?: string;
    }[];
  };
  companiesHouse: {
    data: {
      companyRegistrationNumber: string;
      name: string;
      address: string;
    }[];
  };
};

export type CompanySelectActions =
  | CompanySelectApiSuccessAction
  | CompanySelectApiFailAction
  | CompanySelectQueryAction
  | CompanySelectResetAction;
