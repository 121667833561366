export const PRIMARY_USE_MAP = new Map([
  ['office', 'Office'],
  ['retail', 'Retail'],
  ['industrial', 'Industrial'],
]);

export const FREQUENCY_MAP = new Map([
  ['weekly', 'Weekly'],
  ['monthly', 'Monthly'],
]);

export const SECTOR_MAP = new Map([
  ['administrative-support-services', 'Administrative & Support Services'],
  ['agriculture-forestry-fishing', 'Agriculture, Forestry & Fishing'],
  [
    'architecture-engineering-related-technical-consultancy',
    'Architecture, Engineering & Related Technical Consultancy',
  ],
  ['charity-non-profit', 'Charity & Non-Profit'],
  ['construction', 'Construction'],
  ['creative', 'Creative'],
  ['education', 'Education'],
  ['finance', 'Finance'],
  ['government', 'Government'],
  ['health-social-care', 'Health & Social Care'],
  ['individual', 'Individual'],
  ['insurance', 'Insurance'],
  ['legal', 'Legal'],
  ['manufacturing', 'Manufacturing'],
  ['materials', 'Materials'],
  ['media', 'Media'],
  ['oil-gas', 'Oil & Gas'],
  ['other', 'Other'],
  ['real-estate', 'Real Estate - Advisory'],
  ['real-estate - charity', 'Real Estate - Charity'],
  ['real-estate - council', 'Real Estate - Council'],
  ['real-estate - developer', 'Real Estate - Developer'],
  ['real-estate - institution', 'Real Estate - Institution'],
  ['real-estate - other', 'Real Estate - Other'],
  ['real-estate - private-equity', 'Real Estate - Private Equity'],
  ['real-estate - property-company', 'Real Estate - Property Company'],
  ['real-estate - reit', 'Real Estate - REIT'],
  ['recreation-leisure', 'Recreation & Leisure'],
  ['recruitment', 'Recruitment'],
  ['religious-institution', 'Religious Institution'],
  ['retail', 'Retail'],
  ['scientific-technical', 'Scientific & Technical'],
  ['security-services', 'Security Services'],
  ['serviced-offices', 'Serviced Offices'],
  ['technology', 'Technology'],
  ['telecoms', 'Telecoms'],
  ['transportation-storage', 'Transportation & Storage'],
  ['utilities', 'Utilities'],
]);

export const TRUE_FALSE_MAP = new Map([
  [true, 'Yes'],
  [false, 'No'],
]);

export const LEASE_TYPE_MAP = new Map([
  ['lease', 'Lease'],
  ['pre-let', 'Pre-let'],
  ['sub-lease', 'Sub-lease'],
  ['sub-lease-or-assignment', 'Sub-lease or Assignment'],
  ['assignment', 'Assignment'],
  ['reversionary', 'Reversionary'],
  ['license', 'license'],
  ['renewal', 'Renewal'],
]);

export const LEASE_STATUS_MAP = new Map([
  ['to-let', 'To Let'],
  ['under-offer', 'Under Offer'],
  ['let', 'Let'],
  ['terminated', 'Terminated'],
  ['let', 'Let'],
  ['letFromTerminated', 'Let'],
]);

export const REPAIRING_OBLIGATIONS_MAP = new Map([
  ['fri', 'FRI'],
  ['iri', 'IRI'],
  ['other', 'Other'],
  ['unknown', 'Unknown'],
]);

export const MEASUREMENT_STANDARD_MAP = new Map([
  ['gea', 'GEA'],
  ['gia', 'GIA'],
  ['ipms', 'IPMS'],
  ['nia', 'NIA'],
]);

export const INSIDE_OUTSIDE_MAP = new Map([
  [true, 'Inside'],
  [false, 'Outside'],
]);

export const BREAK_TYPE_MAP = new Map([
  ['mutual', 'Mutual'],
  ['landlord', 'Landlord'],
  ['tenant', 'Tenant'],
]);

export const COLLAR_TYPE_MAP = new Map([
  ['percentage', 'Percentage'],
  ['pounds-and-pence', 'Pounds and Pence'],
]);

export const REVIEW_TYPE_MAP = new Map([
  ['open-market-review', 'Open Market Review'],
  ['stepped-rent', 'Stepped Rent'],
  ['cpi', 'CPI'],
  ['rpi', 'RPI'],
  ['other-index', 'Other Index'],
]);

export const TENURE_MAP = new Map([
  ['freehold', 'Freehold'],
  ['feuhold', 'Feuhold'],
  ['leasehold', 'Long Leasehold'],
  ['part-freehold-part-leasehold', 'Part Freehold / Part Long Leasehold'],
]);

export const SALE_STATUS = new Map([
  ['outcome-unknown', 'Outcome Unknown'],
  ['available', 'Available'],
  ['under-offer', 'Under Offer'],
  ['sold', 'Sold'],
  ['withdrawn', 'Withdrawn'],
]);

export const GRADE_TYPE_MAP = new Map([
  ['design-build', 'Design & Build'],
  ['retail', 'Local Authority allocation site'],
  ['industrial', 'New - New Build (existing)'],
  ['new-build-pre-construction', 'New - New Build (pre-construction)'],
  ['new-build-construction', 'New - New Build (under construction)'],
  ['new-refurb-existing', 'New - Refurb (existing)'],
  ['new-refurb-pre-construction', 'New - Refurb (pre-construction)'],
  ['new-refurb-under-construction', 'New - Refurb (under construction)'],
  ['not-applicable', 'Not Applicable'],
  ['second-hand-retail', 'Second-hand (Retail)'],
  ['second-hand-undisclosed', 'Second-hand (undisclosed)'],
  ['second-hand-purpose-built-office', 'Second-hand Purpose-built Office'],
  ['second-hand-converted-office', 'Second-hand Converted Office'],
]);

export const EPC_MAP = new Map([
  ['A+', 'A+'],
  ['A', 'A'],
  ['B', 'B'],
  ['C', 'C'],
  ['D', 'D'],
  ['E', 'E'],
  ['F', 'F'],
  ['G', 'G'],
]);

export const AMENITIES_MAP = new Map([
  ['24-hour-access', '24 Hour Access'],
  ['24-hour-security', '24 Hour Security'],
  ['24-hour_porterage', '24-Hour Porterage'],
  ['4-pipe-fan-coil-air-conditioning', '4 Pipe Fan Coil Air Conditioning'],
  ['abuts-railway', 'Abuts Railway'],
  ['air-conditioning', 'Air Conditioning'],
  ['alarm', 'Alarm'],
  ['atms', 'ATMs'],
  ['atrium', 'Atrium'],
  ['baby-facilities', 'Baby Facilities'],
  ['bicycle-facilities', 'Bicycle Facilities'],
  ['bowling-alley', 'Bowling alley'],
  ['BREEAM-excellent', 'BREEAM excellent'],
  ['BREEAM-outstanding', 'BREEAM outstanding'],
  ['BREEAM-very-good', 'BREEAM very good'],
  ['broadband', 'Broadband'],
  ['building-reception', 'Building Reception'],
  ['bulky-goods', 'Bulky Goods'],
  ['planning-consent', 'Planning Consent'],
  ['car-wash', 'Car wash'],
  ['carpeted-offices', 'Carpeted Offices'],
  ['cat-ii-lighting', 'Cat II Lighting'],
  ['cat-v-cabling', 'Cat V Cabling'],
  ['cctv', 'CCTV'],
  ['central-heating', 'Central Heating'],
  ['chapel', 'Chapel'],
  ['cinema', 'Cinema'],
  ['comfort-cooling', 'Comfort Cooling '],
  ['commissionaire', 'Commissionaire'],
  ['concierge', 'Concierge'],
  ['creche', 'Creche'],
  ['customer-card', 'Customer Card'],
  ['customer-services-desk', 'Customer Services Desk'],
  ['dentist', 'Dentist'],
  ['disabled-access', 'Disabled Access'],
  ['disabled-parking', 'Disabled Parking'],
  ['disabled-toilets', 'Disabled Toilets'],
  ['dockside', 'Dockside'],
  ['double-glazing', 'Double Glazing'],
  ['electric-heating', 'Electric Heating'],
  ['entry-phone', 'Entry Phone'],
  ['escalators', 'Escalator(s)'],
  ['events-programme', 'Events Programme'],
  ['food-court', 'Food Court'],
  ['fully-fitted-out', 'Fully fitted out'],
  ['gated-development', 'Gated Development '],
  ['goog-lifts', 'Goods lift(s)'],
  ['gym', 'Gym'],
  ['heritage-centre', 'Heritage Centre'],
  ['ink-rink', 'Ice Rink'],
  ['information-point', 'Information point'],
  ['kiosk-barrow-traders', 'Kiosks/barrow Traders'],
  ['kitchenette', 'Kitchenette'],
  ['landscaped-gardens', 'Landscaped Garden(s)'],
  ['leisure-sports-facilities', 'Leisure/Sports Facilities'],
  ['lg3-lighting', 'LG3 Lighting'],
  ['lg7-lighting', 'LG7 Lighting '],
  ['library', 'Library'],
  ['lift/s', 'Lift/s'],
  ['loading-bays', 'Loading bay(s)'],
  ['marble-ceilings', 'Marble Ceilings'],
  ['medical-centre', 'Medical centre'],
  ['metal-ceilings', 'Metal Ceilings'],
  ['multiplex-cinema', 'Mutliplex cinema'],
  ['natural-light', 'Natural Light'],
  ['nightclub', 'Nightclub '],
  ['open-planning-consent', 'Open Planning Consent'],
  ['park-n-ride-facilities', 'Park n Ride Facilities'],
  ['parking-spaces', 'Parking Spaces'],
  ['perimeter-trunking', 'Perimeter Trunking'],
  ['petrol-station', 'Petrol Station'],
  ['play-centre', 'Play centre'],
  ['police-station-on-site', 'Police station (on-site)'],
  ['public-transport-interchange', 'Public Transport Interchange '],
  ['raised-floors', 'Raised Floors'],
  ['recycling-facilities', 'Recycling facilities '],
  ['river-canalside', 'River/Canalside'],
  ['roller-shutter-access', 'Roller Shutter Access'],
  ['roof-terrace', 'Roof Terrace'],
  ['security', 'Security'],
  ['separate-entrance', 'Separate Entrance'],
  ['separate-kitchen', 'Separate Kitchen'],
  ['shell-loft-apartments', 'Shell/Loft Apartments'],
  ['shopmobilitiy', 'Shopmobility'],
  ['shopper-lockers', 'Shopper lockers'],
  ['shoppertainment-programme', 'Shoppertainment Programme'],
  ['showers', 'Showers'],
  ['stairs', 'Stairs'],
  ['storage', 'Storage'],
  ['supermarket', 'Supermarket'],
  ['suspended-ceilings', 'Suspended Ceilings'],
  ['swimming-pool', 'Swimming Pool'],
  ['taxi-rank', 'Taxi rank'],
  ['three-phase-power', 'Three-Phase Power'],
  ['toilets', 'Toilets'],
  ['tourist-office', 'Tourist Office'],
  ['under-floor-trunking', 'Under Floor Trunking'],
  ['vav-air-conditioning', 'VAV Air Conditioning '],
  ['versatemp-air-cooling', 'Versatemp Air Cooling'],
  ['wcs-on-each-floor', 'WCs on each floor'],
  ['wcs', 'WCs'],
  ['wooden-floors', 'Wooden Floors'],
  ['wiredscore-gold', 'WiredScore Gold'],
  ['wiredscore-platinum', 'WiredScore Platinum '],
  ['wiredscore-silver', 'WiredScore Silver'],
]);

export const ROLE_MAP = new Map([
  ['Disposing Agent', "Seller's Agent"],
  ['Acquiring Agent', `Buyer's Agent`],
  ['Purchaser', `Purchaser`],
  ['Vendor', `Vendor`],
  ['Tenant', `Tenant`],
  ['Tenant Agent', `Tenant's Agent`],
  ['Landlord Agent', `Landlord's Agent`],
  ['Holding Entity', `Holding Entity`],
  ['Tenant', `Tenant`],
  ['Landlord', `Landlord`],
  ['Tenant Agent', `Tenant's Agent`],
  ['Landlord Agent', `Landlord's Agent`],
  ['Holding Entity', `Holding Entity`],
  [`Buyer's Solicitor`, `Buyer's Solicitor`],
  [`Seller's Solicitor`, `Seller's Solicitor`],
  ['Other', `Other`],
  [`Tenant's Lease Consultant`, `Tenant's Lease Consultant`],
  [`Tenant's Solicitor`, `Tenant's Solicitor`],
  [`Landlord's Lease Consultant`, `Landlord's Lease Consultant`],
  [`Landlord's Solicitor`, `Landlord's Solicitor`],
  [`Occupier Services Provider`, `Occupier Services Provider`],
  ['Asset Manager', `Asset Manager`],
  ['Debt Provider', `Debt Provider`],
  ['Property Manager', `Property Manager`],
  ['Fund Manager', `Fund Manager`],
  ['Dilapidations Manager', `Dilapidations Manager`],
  ['Building and Property Consultant', `Building and Property Consultant`],
  ['Property Valuer', `Property Valuer`],
]);

export const RENT_TYPE_MAP = new Map([
  ['rents-received', 'Rents Received'],
  ['rents-receivable', 'Rents Receivable'],
]);

export const DOCUMENT_TYPE_MAP = new Map([
  ['associated-lease-doc', 'Associated Lease Doc'],
  ['company-annual-account', 'Company Annual Account'],
  ['development-appraisal', 'Development Appraisal'],
  ['floor-plan-measurement-survey', 'Floor Plan & Measurement Survey'],
  ['heads-of-terms', 'Heads of Terms'],
  ['image', 'Image'],
  ['investment-brochure', 'Investment Brochure'],
  ['investment-summary', 'Investment Summary'],
  ['lease', 'Lease'],
  ['letting-particulars', 'Letting Particulars'],
  ['planning-development-document', 'Planning & Development Document'],
  ['press-release', 'Press Release'],
  ['tenancy-schedule', 'Tenancy Schedule'],
  ['title-doc', 'Title Doc'],
  ['valuation-comps-evidence', 'Valuation & Comps Evidence'],
  ['other', 'Other'],
]);

export const SOURCE_TYPE_MAP = new Map([
  ['investment-brochure', 'Investment Brochure'],
  ['head-of-terms', 'Head of Terms'],
  ['word-of-mouth', 'Word of Mouth'],
  ['savills-research', 'Savills Research'],
  ['company-accounts', 'Company Accounts'],
  ['news', 'Industry News / Press Release'],
  ['comparable-call', 'Comparable Call'],
  ['marketing-particulars', 'Marketing Particulars'],
  ['squid-data', 'SQUID Data'],
  ['other', 'Other'],
  ['press-release-industry-news', 'Press Release / Industry News'],
  ['comp-call-landlords-agent', "Comp Call - Landlord's Agent"],
  ['comp-call-tenants-agent', "Comp Call - Tenant's Agent"],
  ['comp-call-sellers-agent', "Comp Call - Seller's Agent"],
  ['comp-call-buyers-agent', "Comp Call - Buyer's Agent"],
  ['comp-call-landlord', 'Comp Call - Landlord'],
  ['comp-call-tenant', 'Comp Call - Tenant'],
  ['comp-call-buyer', 'Comp Call - Buyer'],
  ['comp-call-seller', 'Comp Call - Seller'],
  ['rent-review-memo', 'Rent Review Memo'],
  ['floor-plan-measurements', 'Floor Plan & Measurements'],
  ['valuation-report', 'Valuation Report'],
  ['planning-application-report', 'Planning Application/Report'],
  ['epc-report', 'EPC Report'],
  ['tenancy-schedule', 'Tenancy Schedule'],
  ['title-document', 'Title Document'],
  ['building-website', 'Building Website'],
  ['scottish-assessors-association', 'Scottish Assessors Association'],
  ['valuation-office-agency', 'Valuation Office Agency'],
  ['Companies House', 'Companies House'],
  ['EG Radius', 'EG Radius'],
  ['Dynamics', 'Dynamics'],
  ['Leverton', 'Leverton Lease Extraction'],
  ['Harness', 'Harness Investment Brochure Extraction'],
  ['AddressBase', 'AddressBase'],
  ['LandRegistry', 'HM Land Registry'],
]);

export const EVENT_MAP = new Map([
  ['break', 'Break'],
  ['expiry', 'Expiry'],
  ['expires', 'Expiry'],
]);

export const FLOOR_USE_MAP = new Map([
  ['ancillary', 'Ancillary'],
  ['bma', 'BMA'],
  ['industrial', 'Industrial'],
  ['lost-space', 'Lost Space'],
  ['office', 'Office'],
  ['reception', 'Reception'],
  ['retail', 'Retail'],
  ['retail-ancillary', 'Retail Ancillary'],
  ['other', 'Other'],
  ['Unknown', 'Unknown'],
]);

export const PRIMARY_USE_SUB_CATEGORIES_MAP = new Map([
  ['industrial-distribution-parks-b-8', 'Industrial: Distribution Parks (B8)'],
  ['industrial-factory-b-2', 'Industrial: Factory (B2)'],
  ['industrial-garage-workshop-b-1-c', 'Industrial: Garage / Workshop (B1c)'],
  ['industrial-general-b-2', 'Industrial: General (B2)'],
  ['industrial-heavy-b-1-2', 'Industrial: Heavy (B1/2)'],
  [
    'industrial-industrial-park-b-1-2-8',
    'Industrial: Industrial Park (B1/2/8)',
  ],
  [
    'industrial-light-industrial-business-units-b-1-c',
    'Industrial: Light Industrial / Business Units (B1c)',
  ],
  ['industrial-mixed-b-1-2-8', 'Industrial: Mixed (B1/2/8)'],
  [
    'industrial-storage-and-distribution-b-8',
    'Industrial: Storage and Distribution (B8)',
  ],
  [
    'industrial-tyre-exhaust-motorists-centres-b-1-c',
    'Industrial: Tyre & Exhaust/Motorists Centres (B1c)',
  ],
  ['life-sciences', 'Life Sciences'],
  ['office-business-park-b-1-a', 'Office: Business Park (B1a)'],
  ['office-business-parks-b-1-b', 'Office: Business Parks (B1b)'],
  ['office-call-centre-b-1-a', 'Office: Call Centre (B1a)'],
  [
    'office-call-centre-business-park-b-1-a',
    'Office: Call Centre - Business Park (B1a)',
  ],
  ['office-call-centre-office-b-1-a', 'Office: Call Centre - Office (B1a)'],
  [
    'office-call-centre-warehouse-b-1-a',
    'Office: Call Centre - Warehouse (B1a)',
  ],
  ['office-general-b-1-a', 'Office: General (B1a)'],
  ['office-government-b-1-a', 'Office: Government (B1a)'],
  ['office-research-development-b-1-b', 'Office: Research & Development (B1b)'],
  ['office-r-d-high-tech-b-1-b', 'Office: R&D/High Tech (B1b)'],
  ['office-science-parks-b-1-b', 'Office: Science Parks (B1b)'],
  ['office-serviced-b-1-a', 'Office: Serviced (B1a)'],
  ['office-training-centre-b-1-a', 'Office: Training Centre (B1a)'],
  ['retail-betting-shop', 'Retail: Betting Shop'],
  [
    'retail-cash-carry-wholesale-warehouse-club',
    'Retail: Cash & Carry/Wholesale Warehouse/Club',
  ],
  ['retail-department-stores-a-1-2-3', 'Retail: Department Stores (A1/2/3)'],
  ['retail-domestic-hire-shops-a-1', 'Retail: Domestic Hire Shops (A1)'],
  ['retail-dry-cleaners-a-1', 'Retail: Dry Cleaners (A1)'],
  ['retail-factory-outlet-centre-a-1', 'Retail: Factory Outlet Centre (A1)'],
  ['retail-financial-a-2', 'Retail: Financial (A2)'],
  [
    'retail-financial-professional-services-a-2',
    'Retail: Financial & Professional Services (A2)',
  ],
  ['retail-foodstore-supermarket-a-1', 'Retail: Foodstore/Supermarket (A1)'],
  ['retail-funeral-directors-a-1', 'Retail: Funeral Directors (A1)'],
  ['retail-garden-centres-a-1', 'Retail: Garden Centres (A1)'],
  ['retail-general-a-1', 'Retail: General (A1)'],
  ['retail-hairdressers-a-1', 'Retail: Hairdressers (A1)'],
  [
    'retail-hot-food-take-away-food-drink-a-5',
    'Retail: Hot Food Take Away (Food & Drink) (A5)',
  ],
  [
    'retail-hot-food-take-away-drive-thru-a-5',
    'Retail: Hot Food Take-Away Drive-Thru (A5)',
  ],
  ['retail-internet-cafes-a-1', 'Retail: Internet Cafes (A1)'],
  [
    'retail-mixed-use-a-1-2-3-4-5-b-1-or-d-1',
    'Retail: Mixed-use (A1/2/3/4/5, B1 or D1)',
  ],
  [
    'retail-mixed-use-retail-and-leisure-a-1-2-3-4-5-d-2',
    'Retail: Mixed-use Retail and Leisure (A1/2/3/4/5/D2)',
  ],
  ['retail-motorway-service-areas-a-1', 'Retail: Motorway Service Areas (A1)'],
  [
    'retail-non-food-retail-warehouse-a-1',
    'Retail: Non Food Retail Warehouse (A1)',
  ],
  ['retail-post-office-a-1', 'Retail: Post Office (A1)'],
  ['retail-professional-a-2', 'Retail: Professional (A2)'],
  [
    'retail-restaurants-and-cafes-food-drink-a-3',
    'Retail: Restaurants and Cafes (Food & Drink) (A3)',
  ],
  ['retail-retail-park-a-1-2-3-4-5', 'Retail: Retail Park (A1/2/3/4/5)'],
  [
    'retail-sandwich-bars-cold-food-a-1',
    'Retail: Sandwich Bars (Cold Food) (A1)',
  ],
  [
    'retail-shopping-centre-a-1-2-3-4-5',
    'Retail: Shopping Centre (A1/2/3/4/5)',
  ],
  ['retail-showrooms-general-a-1', 'Retail: Showrooms - General (A1)'],
  ['retail-travel-ticket-agencies-a-1', 'Retail: Travel/Ticket Agencies (A1)'],
]);

export const INVESTMENT_SUB_CATEGORIES_MAP = new Map([
  [
    'industrial-single-let-distribution-and-logistics',
    'Industrial: Single-let / distribution & logistics',
  ],
  ['industrial-multi-let', 'Industrial: Multi-let'],
  [
    'industrial-industrial-outdoor-storage',
    'Industrial: Industrial Outdoor Storage',
  ],
]);

export const INVESTMENT_CATEGORY_MAP = new Map([
  ['Core', 'Core'],
  ['Core +', 'Core +'],
  ['Core ++', 'Core ++'],
  ['Value Add', 'Value Add'],
  ['Opportunistic', 'Opportunistic'],
]);

export const GRADE_MAP = new Map([
  ['A', 'A'],
  ['B', 'B'],
  ['C', 'C'],
  ['LAB', 'LAB'],
  ['Prime A', 'Prime A'],
]);

export const GRADE2_MAP = new Map([
  ['A1', 'A1'],
  ['A2', 'A2'],
  ['A3', 'A3'],
  ['B1', 'B1'],
  ['B2', 'B2'],
  ['B3', 'B3'],
  ['C1', 'C1'],
  ['C2', 'C2'],
  ['C3', 'C3'],
]);

export const FIT_OUT_CATEGORY_MAP = new Map([
  ['Cat A', 'Cat A'],
  ['Cat B', 'Cat B'],
  ['Cat A+', 'Cat A+'],
  ['Cat A Terrace', 'Cat A Terrace'],
  ['Shell and Core', 'Shell and Core'],
]);

export const BREEAM_RATING_MAP = new Map([
  ['outstanding', 'Outstanding'],
  ['excellent', 'Excellent'],
  ['very good', 'Very Good'],
  ['good', 'Good'],
  ['unclassified', 'Unclassified'],
  ['pass', 'Pass'],
]);

export const WIRED_SCORE_RATING_MAP = new Map([
  ['Wired Certified Platinum', 'Wired Certified Platinum'],
  ['Wired Certified Gold', 'Wired Certified Gold'],
  ['Wired Certified Silver', 'Wired Certified Silver'],
  ['Wired Certified', 'Wired Certified'],
]);

export const WELL_CERTIFICATION_MAP = new Map([
  ['WELL Bronze', 'WELL Bronze'],
  ['WELL Silver', 'WELL Silver'],
  ['WELL Gold', 'WELL Gold'],
  ['WELL Platinum', 'WELL Platinum'],
  ['WELL Core Bronze', 'WELL Core Bronze'],
  ['WELL Core Silver', 'WELL Core Silver'],
  ['WELL Core Gold', 'WELL Core Gold'],
  ['WELL Core Platinum', 'WELL Core Platinum'],
]);

export const JOB_HISTORY_MAP = new Map([
  ['All', 'All'],
  ['Agency (Acquisition)', 'Agency (Acquisition)'],
  ['Agency (Disposal)', 'Agency (Disposal)'],
  ['Agribusiness consultancy', 'Agribusiness consultancy'],
  ['Agribusiness Consultancy', 'Agribusiness Consultancy'],
  ['Agribusiness Management', 'Agribusiness Management'],
  ['Agricultural Agency', 'Agricultural Agency'],
  ['Arbitration & Third Party Work', 'Arbitration & Third Party Work'],
  ['Architectural Services', 'Architectural Services'],
  ['Asset Management', 'Asset Management'],
  ['Block Management', 'Block Management'],
  ['Building Survey & Prof. Work', 'Building Survey & Prof. Work'],
  ['Capital Allowances', 'Capital Allowances'],
  ['Client Accounting', 'Client Accounting'],
  ['Client Accounting Recharges', 'Client Accounting Recharges'],
  [
    'Community Infrastructure Levy (CIL)',
    'Community Infrastructure Levy (CIL)',
  ],
  ['Construction & Design Management', 'Construction & Design Management'],
  ['Contract Administration', 'Contract Administration'],
  ['Cost Consultancy', 'Cost Consultancy'],
  ['CPO', 'CPO'],
  ['Defect Diagnostics', 'Defect Diagnostics'],
  ['Development Agency', 'Development Agency'],
  ['Development Consultancy', 'Development Consultancy'],
  ['Development Funding', 'Development Funding'],
  ['Development Monitoring', 'Development Monitoring'],
  ['Dilapidations Consultancy', 'Dilapidations Consultancy'],
  [
    'Environmental Impact Assessment (EIA)',
    'Environmental Impact Assessment (EIA)',
  ],
  ['Estate Management', 'Estate Management'],
  ['Feasibility', 'Feasibility'],
  ['Feasibility Study', 'Feasibility Study'],
  ['Fire Risk Assessments', 'Fire Risk Assessments'],
  ['Forestry', 'Forestry'],
  [
    'General Advice / General Consultancy',
    'General Advice / General Consultancy',
  ],
  ['GIS/Mapping', 'GIS/Mapping'],
  ['Health & Safety', 'Health & Safety'],
  ['Heritage', 'Heritage'],
  [
    'Impact assessment – Carbon footprinting',
    'Impact assessment – Carbon footprinting',
  ],
  ['Independent Expert', 'Independent Expert'],
  ['Insolvency Advice', 'Insolvency Advice'],
  ['Insurance Loss Reinstatement', 'Insurance Loss Reinstatement'],
  ['Intercompany', 'Intercompany'],
  ['International Residential Agency', 'International Residential Agency'],
  ['Investment (Acquisition)', 'Investment (Acquisition)'],
  ['Investment (Disposal)', 'Investment (Disposal)'],
  ['Landlord and Tenant (Other)', 'Landlord and Tenant (Other)'],
  ['Landscape Architecture', 'Landscape Architecture'],
  ['Lease Plans', 'Lease Plans'],
  ['Lease Re-gear (Landlord)', 'Lease Re-gear (Landlord)'],
  ['Lease Re-gear (Tenant)', 'Lease Re-gear (Tenant)'],
  ['Lease Renewal (Landlord)', 'Lease Renewal (Landlord)'],
  ['Lease Renewal (Tenant)', 'Lease Renewal (Tenant)'],
  ['Lease Surrender (Landlord)', 'Lease Surrender (Landlord)'],
  ['Lease Surrender (Tenant)', 'Lease Surrender (Tenant)'],
  [
    'Leasing (acting for Occupier / Tenant)',
    'Leasing (acting for Occupier / Tenant)',
  ],
  [
    'Leasing (acting for Owner / Landlord)',
    'Leasing (acting for Owner / Landlord)',
  ],
  ['Licence Alterations', 'Licence Alterations'],
  ['Licence for Alterations', 'Licence for Alterations'],
  ['Litigation and dispute resolution', 'Litigation and dispute resolution'],
  ['LPA Receivership', 'LPA Receivership'],
  ['LRA - Landlord', 'LRA - Landlord'],
  ['LRA - Tenant', 'LRA - Tenant'],
  [
    'Maintenance, Reburbishment & Repair (Mgt)',
    'Maintenance, Reburbishment & Repair (Mgt)',
  ],
  [
    'Management - Institutional & Charities',
    'Management - Institutional & Charities',
  ],
  ['Management  - Private Clients', 'Management  - Private Clients'],
  ['Management - Private Clients', 'Management - Private Clients'],
  [
    'Management - Public Sector & Corporates',
    'Management - Public Sector & Corporates',
  ],
  ['Marketing', 'Marketing'],
  ['Master Planning', 'Master Planning'],
  ['Minerals', 'Minerals'],
  ['Minor Works', 'Minor Works'],
  ['Natural Capital', 'Natural Capital'],
  ['Office Fit-out & Relocation', 'Office Fit-out & Relocation'],
  ['Party Walls', 'Party Walls'],
  ['Planned Maintenance Programme', 'Planned Maintenance Programme'],
  [
    'Planned Preventative Maintenance Schedules (PPM)',
    'Planned Preventative Maintenance Schedules (PPM)',
  ],
  ['Planning', 'Planning'],
  ['Planning Appeal', 'Planning Appeal'],
  [
    'Procurement & Investment Consultancy',
    'Procurement & Investment Consultancy',
  ],
  ['Project and Development Monitoring', 'Project and Development Monitoring'],
  ['Project Management', 'Project Management'],
  ['Property Management (Ad-hoc)', 'Property Management (Ad-hoc)'],
  ['Property Management (Commercial)', 'Property Management (Commercial)'],
  ['Property Management (PRS)', 'Property Management (PRS)'],
  ['Property Management (Residential)', 'Property Management (Residential)'],
  ['Property Management (Retirement)', 'Property Management (Retirement)'],
  ['Property Management Set-up', 'Property Management Set-up'],
  ['Rating', 'Rating'],
  [
    'RCS Maintenance, Refurbishment & Repair',
    'RCS Maintenance, Refurbishment & Repair',
  ],
  ['Receivership Consultancy', 'Receivership Consultancy'],
  ['Recoverable Costs', 'Recoverable Costs'],
  ['Reinstatement Cost Assessment', 'Reinstatement Cost Assessment'],
  ['Renewables Agency', 'Renewables Agency'],
  ['Renewables consultancy', 'Renewables consultancy'],
  ['Rent Review (Landlord)', 'Rent Review (Landlord)'],
  ['Rent Review (Tenant)', 'Rent Review (Tenant)'],
  ['Research', 'Research'],
  ['Residential Agency', 'Residential Agency'],
  ['Residential Development Sales', 'Residential Development Sales'],
  ['Residential Lettings', 'Residential Lettings'],
  ['Rights of Light', 'Rights of Light'],
  ['Schedule of Condition', 'Schedule of Condition'],
  ['Service Charge Consultancy', 'Service Charge Consultancy'],
  ['Staff Secondment', 'Staff Secondment'],
  ['Statistical Information', 'Statistical Information'],
  ['Stock Condition Surveys', 'Stock Condition Surveys'],
  ['Stock Rationalisation', 'Stock Rationalisation'],
  ['Strategic', 'Strategic'],
  ['Sub-contractor Services', 'Sub-contractor Services'],
  ['Tax', 'Tax'],
  ['Technical Due Diligence', 'Technical Due Diligence'],
  ['Training and Development', 'Training and Development'],
  ['Trust & Tax', 'Trust & Tax'],
  ['Urban Design', 'Urban Design'],
  ['Valuation - Accounts', 'Valuation - Accounts'],
  ['Valuation - Agency', 'Valuation - Agency'],
  ['Valuation - Desktop Appraisal', 'Valuation - Desktop Appraisal'],
  ['Valuation - Expert Witness', 'Valuation - Expert Witness'],
  ['Valuation - Funds', 'Valuation - Funds'],
  ['Valuation - Loan Security', 'Valuation - Loan Security'],
  ['Valuation - LRA', 'Valuation - LRA'],
  ['Valuation - Other', 'Valuation - Other'],
  ['Valuation - Trust & Tax', 'Valuation - Trust & Tax'],
  ['Viability', 'Viability'],
  ['Workplace Consultancy & Design', 'Workplace Consultancy & Design'],
  ['Workplace Consult & Design', 'Workplace Consult & Design'],
]);

export const JOB_CATEGORY_MAP = new Map([
  ['All', 'All'],
  ['Architecture, Workplace & Design', 'Architecture, Workplace & Design'],
  ['Capital Allowances & Tax', 'Capital Allowances & Tax'],
  ['Consultancy', 'Consultancy'],
  ['CPO', 'CPO'],
  ['Debt & Capital Advisory', 'Debt & Capital Advisory'],
  ['ESG', 'ESG'],
  [
    'Expert Witness & Dispute Resolution',
    'Expert Witness & Dispute Resolution',
  ],
  ['Lease Consultancy', 'Lease Consultancy'],
  ['Leasing', 'Leasing'],
  ['People and Training', 'People and Training'],
  ['Planning', 'Planning'],
  ['Professional Building Consultancy', 'Professional Building Consultancy'],
  ['Project Management & Fit Out', 'Project Management & Fit Out'],
  ['Property Management', 'Property Management'],
  ['Rating', 'Rating'],
  ['Receivership', 'Receivership'],
  ['Research & Marketing', 'Research & Marketing'],
  ['Residential Sales & Lettings', 'Residential Sales & Lettings'],
  ['Sales & Acquisitions', 'Sales & Acquisitions'],
  ['Sustainability', 'Sustainability'],
  ['Valuation', 'Valuation'],
  ['Workplace Strategy & Design', 'Workplace Strategy & Design'],
]);

export const SOURCE_MAP = new Map([
  ['user', 'User'],
  ['LandRegistry', 'Land Registry'],
  ['EG Radius', 'Radius'],
  ['Harness', 'Harness'],
  ['Leverton', 'Leverton'],
  ['Kato', 'Kato'],
]);

export const EQUIVALENT_BOND_RATING_MAP = new Map([
  ['A', 'A'],
  ['A-', 'A-'],
  ['A+', 'A+'],
  ['AA-', 'AA-'],
  ['AA & Above', 'AA & Above'],
  ['B', 'B'],
  ['B-', 'B-'],
  ['B+', 'B+'],
  ['BB', 'BB'],
  ['BB-', 'BB-'],
  ['BB+', 'BB+'],
  ['BBB', 'BBB'],
  ['BBB-', 'BBB-'],
  ['BBB+', 'BBB+'],
  ['CCC & below', 'CCC & below'],
  ['CCC+', 'CCC+'],
  ['NA', 'NA'],
]);

export const LISTING_TYPE_MAP = new Map([
  ['Unlisted', 'Unlisted'],
  ['Grade A', 'Grade A'],
  ['Grade B', 'Grade B'],
  ['Grade C', 'Grade C'],
  ['Grade I', 'Grade I'],
  ['Grade II*', 'Grade II*'],
  ['Grade II', 'Grade II'],
]);
